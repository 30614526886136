import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollTop = ({ behavior = "auto" }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: behavior,
    });
  }, [pathname, behavior]);

  return null;
};

export default ScrollTop;
