import { ActionTypes } from "../constant/action-type";

const initialState = {
  fundNameJourney: "",
};

export const journeyFundsReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.JOURNEYFUNDS:
      return { ...state, fundNameJourney: payload };
    default:
      return state;
  }
};
