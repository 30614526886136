import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./container/redux/store";
import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/font-awesome/css/font-awesome.min.css";
import "./assets/scss/argon-design-system-react.scss?v1.1.0";
import "./assets/css/custom.css";
import "antd/dist/antd.min.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import App from "./App";
import { LiveChatLoaderProvider, Messenger } from "react-live-chat-loader";

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
      {/* <LiveChatLoaderProvider
          provider="messenger"
          providerKey="104202187872985"
          // appID="104202187872985"
          locale="en_US"
        >
          <Messenger />
        </LiveChatLoaderProvider> */}
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
