import { combineReducers } from "redux";
import { fundReducers } from "./fundReducer";
import { navpuReducers } from "./navpuReducers";
import { jobReducers } from "./jobReducers";
import { journeyFundsReducer } from "./journeyFundsReducer";
import { journeyFundDataReducer } from "./journeyFundDataReducer";

const reducers = combineReducers({
  fund: fundReducers,
  navpu: navpuReducers,
  job: jobReducers,
  currentJob: jobReducers,
  fundNameJourney: journeyFundsReducer,
  fundNameJourneyData: journeyFundDataReducer,
});

export default reducers;
