import { ActionTypes } from "../constant/action-type";

const initialState = {
  job: "",
  currentJob: "",
};

export const jobReducers = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.JOB:
      return { ...state, job: payload };
    case ActionTypes.CURRENTJOBS:
      return { ...state, currentJob: payload };
    default:
      return state;
  }
};
