import React from "react";
import { Spinner } from "reactstrap";

export default function Loader() {
  return (
    <div className="loader-container">
      <div className="loader-container-inner">
        <Spinner color="primary" size="lg" />
      </div>
    </div>
  );
}
