import { ActionTypes } from "../constant/action-type";

const initialState = {
  fundNameJourneyData: [],
};

export const journeyFundDataReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case ActionTypes.JOURNEYFUNDSDATA:
      return { ...state, fundNameJourneyData: payload };
    default:
      return state;
  }
};
