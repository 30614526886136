import { Fragment, useEffect } from "react";
import Routes from "./routes/Routes";
import { useDispatch } from "react-redux";
import useFetch from "hooks/useFetch";
import { getFunds } from "container/redux/actions/fundAction";
import Loader from "container/shared/Loading";
import ErrorPage from "container/shared/Error";
import ScrollTop from "routes/ScrollTop";

export default function App() {
  const dispatch = useDispatch();
  const { data, loading, error } = useFetch("listFunds");
  useEffect(() => {
    if (data && data.length > 0) {
      const activeFunds = data.filter((fund) => fund.key !== "first_metro");
      if (activeFunds.length > 0) {
        dispatch(getFunds(activeFunds));
      }
    }
  }, [data, dispatch]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorPage />;
  }
  return (
    <Fragment>
      <ScrollTop />
      <Routes />
    </Fragment>
  );
}
