import { ActionTypes } from '../constant/action-type';

const initialState = {
    navpu: [],
}

export const navpuReducers = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.NAVPU:
            return {...state, navpu: payload}
        default:
            return state
    }
}