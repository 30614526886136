import React from "react";

const ErrorPage = (error) => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>{error}</h1>
      <p style={styles.message}>. Please try again later.</p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
  },
  heading: {
    fontSize: "2rem",
    marginBottom: "1rem",
  },
  message: {
    fontSize: "1.5rem",
  },
};

export default ErrorPage;
